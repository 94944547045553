import Type from "./type";
import dayjs from "dayjs";

/**
 * 替换字符串占位符
 * @function
 * @version 1.0.0
 * @param {string} str 传入需要处理的字符串
 * @param {Array | Object} args 传入字符串中要被替换的参数。类型为数组或者object
 * @returns string
 */
export function formatString(str: string, args: Array<any> | any): string {
  let tempStr = str;

  if (arguments.length > 1) {
    if (Type.isObject(args)) {
      for (let key in args) {
        if (args[key] !== undefined) {
          let reg = new RegExp("({" + key + "})", "g");
          tempStr = tempStr.replace(reg, args[key]);
        }
      }
    }

    if (Type.isArray(args)) {
      for (let i = 0; i < args.length; i++) {
        if (args[i] !== undefined) {
          let reg = new RegExp("({)" + i + "(})", "g");
          tempStr = tempStr.replace(reg, args[i]);
        }
      }
    }
  }
  return tempStr;
}

/**
 * 根据过滤条件的数据类型编码条件值。
 * @param  {IFilter} filter 过滤条件实例。
 * @returns string 编码后的字符串。
 */
export function encodeFilterValue(filter: any): string {
  // 如果没有设置类型，则默认做字符串处理
  let filterType = filter.type || String;
  let filterValue = "";

  if (filterType === Array || filterType === Object) {
    // 如果是数组或者JSON对象，则序列化处理
    filterValue = JSON.stringify(filter.value);
  } else {
    // 其他数据类型强制转换为字符串
    if (!Type.isEmptyObject(filter.value)) {
      filterValue = String(filter.value);
    }
  }

  if (filterValue) {
    filterValue = encodeURIComponent(filterValue);
  }

  return filterValue;
}

/**
 * 根据过滤条件的数据类型解码条件值。
 * @param  {IFilter} filter 过滤条件实例。
 * @param  {string} value 需要解码的字符串值。
 * @returns any 解码后的真实数据。
 */
export function decodeFilterValue(filter: any, value: any): any {
  // 如果没有设置类型，则默认做字符串处理
  let filterType = filter.type || String;
  let filterValue: any;

  // 解码字符串值
  value = decodeURIComponent(value);

  switch (filterType) {
    case String:
    case Date: {
      filterValue = value;

      break;
    }
    case Number: {
      filterValue = parseFloat(value);

      break;
    }
    case Boolean: {
      filterValue = value === "true" || value === "1";

      break;
    }
    default: {
      filterValue = JSON.parse(value);

      break;
    }
  }

  return filterValue;
}

/**
 * 下载文件兼容性处理。
 * @param url 下载文件的url
 */
export function downloadFile(url: string, filename?: string): void {
  if (!url || typeof url !== "string") {
    throw new Error("download url is avalid!");
  }

  const linkEl: any = (document as any).createElement("a");

  if ("download" in linkEl) {
    linkEl.download = filename ? filename : url.substr(url.lastIndexOf("/"));
    document.body.appendChild(linkEl);
    linkEl.href = url;
    linkEl.click();
    linkEl.remove();
  } else {
    const win = window.open(url, "_self");
    win.opener = null;
  }
}

/**
 * 获取cookie
 * @param name 需要获取的key
 */
export function getCookie(): any {
  let cookie = document.cookie;
  // let cookie = `OUTFOX_SEARCH_USER_ID_NCOO=1297116724.720062; ___rl__test__cookies=1596681765366; ExternalLogin={"$id":"1","UserId":25753,"UserName":"MGHBSLMP0ELLQmXWuB3lRg==","UserMobile":"MGHBSLMP0ELLQmXWuB3lRg==","Relation":1,"UserSex":null,"timestamp":"1597200529488","nonce":"1438377233","signature":"47F2EB365333E651534E82B11D5762A6D1E1804BA53DE8018466CA0D6492E9BE","IsPerfect":"1","Channel":"买单吧"}`;
  let cookieArr = cookie.split("; ");
  let obj: any = {};
  cookieArr.forEach((item: any) => {
    let index = item.indexOf("=");
    let key = item.substr(0, index);
    let valueStr = item.substr(index + 1, item.length);
    let value = "";
    if (isJSON(valueStr)) {
      value = JSON.parse(valueStr);
    } else {
      value = valueStr;
    }
    obj[key] = value;
  });

  return obj;
}
/**
 * 判断是否是json
 */
export function isJSON(str: string): boolean {
  if (typeof str === "string") {
    try {
      let obj = JSON.parse(str);
      if (typeof obj === "object" && obj) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }
  return false;
}

/**
 * 将指定的 String 或 Date 类型格式化为 `yyyy-mm-dd` 形式。
 * @param  {string|Date} value
 * @returns string
 */
export function formatDate(value: string | Date): string {
  if (!Type.isEmptyObject(value)) {
    return dayjs(value).format("YYYY/MM/DD");
  }

  return null;
}

/**
 * 将指定的 String 或 Date 类型格式化为 `yyyy-mm-dd hh:mm:ss` 形式。
 * @param  {string|Date} value
 * @returns string
 */
export function formatDateTime(value: string | Date): string {
  if (!Type.isEmptyObject(value)) {
    return dayjs(value).format("YYYY/MM/DD HH:mm:ss");
  }

  return null;
}

/**
 * 将指定的 String 或 Date 类型格式化为 `yyyy-mm-dd hh:mm:` 形式。
 * @param  {string|Date} value
 * @returns string
 */
export function formatDateHHMM(value: string | Date): string {
  if (!Type.isEmptyObject(value)) {
    return dayjs(value).format("YYYY/MM/DD HH:mm");
  }

  return null;
}

/**
 * 判断是否是微信端 -- 未判断是否为小程序
 */
export function isWechatH5() {
  let ua = navigator.userAgent.toLowerCase();

  if (ua.indexOf("micromessenger") > -1) {
    return true;
  } else {
    return false;
  }
}

/**
 * 已判断是微信小程序
 */
export function isWechat() {
  if (/micromessenger/i.test(navigator.userAgent) && !/wxwork/i.test(navigator.userAgent)) {
    return new Promise(function (resolve, reject) {
      (wx.miniProgram as any).getEnv((res) => {
        if (res.miniprogram) {
          resolve(false);
        } else {
          resolve(true);
        }
      });
    });
  } else {
    return new Promise(function (resolve, reject) {
      resolve(false);
    });
  }
}

/**
 * 判断是微信小程序环境
 * @private
 * @returns boolean
 */
export function isWeatchMini() {
  if (/micromessenger/i.test(navigator.userAgent) && !/wxwork/i.test(navigator.userAgent)) {
    return new Promise(function (resolve, reject) {
      (wx.miniProgram as any).getEnv((res) => {
        if (res.miniprogram) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  } else {
    return new Promise(function (resolve, reject) {
      resolve(false);
    });
  }
}

/**
 * 判断是否是安卓
 */
export function isAndroid() {
  let ua = navigator.userAgent.toLowerCase();

  if (ua.indexOf("android") > -1) {
    return true;
  } else {
    return false;
  }
}

export function openTargetPage(href: string) {
  let a = document.createElement("a");
  a.setAttribute("href", href);
  a.setAttribute("target", "_blank");
  a.setAttribute("id", "startTelMedicine");
  // 防止反复添加
  if (document.getElementById("startTelMedicine")) {
    document.body.removeChild(document.getElementById("startTelMedicine"));
  }
  document.body.appendChild(a);
  a.click();
}

/**
 * 判断是否是PC
 */
export function isPc() {
  let ua = navigator.userAgent.toLowerCase();
  if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(ua)) {
    return false;
  } else {
    return true;
  }
}
